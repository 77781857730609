import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'

const btoaUTF8 = value => {
  let wordArray = Utf8.parse(value)
  var base64 = Base64.stringify(wordArray)
  return base64
}

export default btoaUTF8
