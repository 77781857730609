import React from 'react'
import RehypeReact from 'rehype-react'
import Adsense from '../components/Adsense'

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: { adsense: Adsense },
}).Compiler

export default renderAst
